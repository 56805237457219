.content-page {
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: map-get($spacers, 3);
    }

    img {
        max-width: 100%;
        height: auto !important;
    }
}
