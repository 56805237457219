// settings
$enable-cssgrid: true;
$enable-shadows: true;
$enable-validation-icons: false;
$enable-negative-margins: true;

// animation
$animation-duration: 200ms;

// font
$font-family-sans-serif: "museo-sans", serif;
$font-family-serif: "museo-slab";
$font-weight-bolder: 900;

// headings
$headings-margin-bottom: 0;
$headings-font-weight: 900;
$headings-color: $dark;

// labels
$form-label-margin-bottom: 0;

// buttons
$btn-font-size: 14px;
$btn-border-radius: 6px;
$btn-padding-y: 7px;
$btn-padding-x: 18px;
$btn-font-weight: $font-weight-bold;

// breadcrumbs
$breadcrumb-padding-y: map-get($spacers, 3);

// placeholder
$input-placeholder-color: $gray-500;

// tooltips
$tooltip-max-width: 400px;
$tooltip-bg: $success;
$tooltip-max-width: 170px;
$tooltip-color: $white;
