.page-header {
    display: grid;
    column-gap: map-get($spacers, 3);
    align-items: center;
    grid-template-areas:
        "title"
        "description"
        "actions";

    @include view-laptop {
        grid-template-columns: min-content auto min-content;
        grid-template-areas:
            "icon title actions"
            "icon description actions";
    }

    &__icon {
        display: none;
        grid-area: icon;
        font-size: $h1-font-size;

        @include view-laptop {
            display: block;
        }
    }

    &__title {
        grid-area: title;
        text-align: center;
        font-family: $font-family-serif;

        @include view-laptop {
            display: block;
            text-align: left;
        }
    }

    &__description {
        grid-area: description;
        text-align: center;

        @include view-laptop {
            display: block;
            text-align: left;
        }
    }

    &__actions {
        grid-area: actions;
        display: flex;
        gap: map-get($spacers, 2);
        padding-top: map-get($spacers, 3);
        flex-wrap: wrap;
        justify-content: center;

        @include view-laptop {
            white-space: nowrap;
            flex-wrap: nowrap;
            padding-top: 0;
            justify-content: flex-start;
        }
    }
}
