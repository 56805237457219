$white: #fff;
$black: #404366;
$blue: #697be9;
$dark: $black;
$light: #99a5ff;

$primary: #f0a31a;
$secondary: $blue;
$warning: #ffec99;
$success: #89b85d;
$danger: #e382b5;
$info: $blue;

// navbar
$nav-link-font-weight: 900;

$link-color: $blue;

// accordion
$accordion-icon-color: $primary;
