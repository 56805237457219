.btn {
    text-shadow: 0px 2px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    white-space: nowrap;

    &.rounded-pill {
        text-shadow: none;
        text-transform: none;
    }
}

.btn-primary {
    color: white;
}

.btn-success {
    color: white;
}

.btn-danger {
    color: white;
}

.btn-link {
    text-transform: none;
    text-shadow: none;

    .fas::before {
        text-decoration-color: transparent !important;
    }
}

.btn-icon {
    display: inline-flex;
    gap: map-get($spacers, 2);
    align-items: center;
}
